import { NavLink } from "react-router-dom";

export default function ReportsHome(){

    return (
      <div className="container">
        <h1 className="display-1">Relatórios</h1>
        <div className="list-group">
          <NavLink
            to="./enrollments"
            className="list-group-item list-group-item-action"
          >
            Inscrições
          </NavLink>
          <NavLink
            to="./questions"
            className="list-group-item list-group-item-action"
          >
            Distribuição das Respostas
          </NavLink>
          <NavLink
            to="./possible-answer-distribution"
            className="list-group-item list-group-item-action"
          >
            Distribuição de probabilidade das respostas
          </NavLink>
        </div>
      </div>
    );
}