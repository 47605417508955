import { ChangeEventHandler } from "react";
import { IInputOption } from "../types/InputOption";
import { InputState } from "../types/InputState";

function inputClassName(state?: InputState) {
  switch (state) {
    case "invalid":
      return "form-control is-invalid";
    case "valid":
      return "form-control is-valid";
    default:
      return "form-control";
  }
}

function feedbackClassName(state?: InputState) {
  switch (state) {
    case "invalid":
      return "invalid-feedback";
    case "valid":
      return "valid-feedback";
    default:
      return "info-feedback";
  }
}
export function InputBox(props: {
  label: string;
  onChange: (value: string) => void;
  value?: string;
  state?: InputState;
  message?: string;
}) {
  const { label, value = "", state, message, onChange } = props;
  const inputChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    onChange(e.target.value);

  return (
    <>
      <label className="form-label">{label}</label>
      <input
        type="text"
        value={value}
        className={inputClassName(state)}
        onChange={inputChange}
      />
      {message ? (
        <div className={feedbackClassName(state)}>{message}</div>
      ) : null}
    </>
  );
}

export function renderRadioBox(
  option: IInputOption,
  i: number,
  onClick: (opt: IInputOption) => void,
  name: string
) {
  return (
    <div className="form-check" key={i}>
      <input
        className="form-check-input"
        type="radio"
        name={name}
        checked={option.selected}
        onChange={() => onClick(option)}
      />
      <label className="form-check-label">{option.label}</label>
    </div>
  );
}

export function renderCheckBox(
  option: IInputOption,
  i: number,
  onClick: (opt: IInputOption) => void
) {
  return (
    <div className="form-check" key={i}>
      <input
        className="form-check-input"
        type="checkbox"
        checked={option.selected}
        onChange={() => onClick(option)}
      />
      <label className="form-check-label">{option.label}</label>
    </div>
  );
}

export function SingleChoiceBox(props: {
  label: string;
  onChange: (options: IInputOption[]) => void;
  options: IInputOption[];
  state?: InputState;
  message?: string;
  name: string;
}) {
  const { label, options, state, message, onChange, name } = props;
  const onClick = (option: IInputOption) => {
    options.forEach((o) => {
      o.selected = o === option;
    });
    onChange(options);
  };
  return (
    <>
      <label className="form-label">{label}</label>
      {options.map((opt, i) => renderRadioBox(opt, i, onClick, name))}
      {state !== "invalid" ? null : (
        <div className={feedbackClassName(state)} style={{ display: "block" }}>
          {message}
        </div>
      )}
    </>
  );
}

export function MultipleChoiceBox(props: {
  label: string;
  onChange: (options: IInputOption[]) => void;
  options: IInputOption[];
  state?: InputState;
  message?: string;
}) {
  const { label, options, state, message, onChange } = props;
  const onClick = (option: IInputOption) => {
    option.selected = !option.selected;
    onChange(options);
  };
  return (
    <>
      <label className="form-label">{label}</label>
      {options.map((opt, i) => renderCheckBox(opt, i, onClick))}
      {state !== "invalid" ? null : (
        <div className={feedbackClassName(state)} style={{ display: "block" }}>
          {message}
        </div>
      )}
    </>
  );
}
